import React from "react";

const Partners = () => {
  return (
    <section className="bg-[#070B38]">
      <div className="flex pt-16 justify-center h-96 ">
        <h1 className="text-5xl text-white font-Montserrat font-bold text-center md:text-left lg:text-start w-[85%]">
          Our Partners
        </h1>
      </div>
    </section>
  );
};

export default Partners;
