import React from 'react'

const Roadmap = () => {
  return (
    <section className="bg-[#FFFF]">
      <div className="flex pt-16 justify-center h-96 ">
        <h1 className="text-5xl text-black font-Montserrat font-bold text-center md:text-left lg:text-start w-[85%]">
          Road Map
        </h1>
      </div>
    </section>
  );
}

export default Roadmap
